import type { ModalCMSProps } from '.';

import { useState } from 'react';

import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Spinner from 'react-bootstrap/Spinner';
import Block from '@components/molecules/block/ClientBlock';
import { useLocale } from 'next-intl';

const ModalCMS = ({ cmsPageId, title, buttonText = 'Öffnen', linkClassName = '' }: ModalCMSProps) => {
    const locale = useLocale()

    const t = (str: string) => str

    const [show, setShow] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [page, setCmsPage] = useState<any | null>(null);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        fetchCmsPage();
        setShow(true);
    }

    const fetchCmsPage = async () => {
        if (null !== page) return

        const res = await fetch('/api/category/' + cmsPageId + '?locale=' + locale, {
            method: "POST",
            body: JSON.stringify({
                includes: {
                    category: [
                        'cmsPage',
                        'translated'
                    ],
                    cms_page: ['cssClass', 'sections', 'translated'],
                    cms_section: ['type', 'blocks', 'translated'],
                    cms_block: ['type', 'slots', 'translated'],
                    cms_slot: ['type', 'data', 'translated'],
                    media: ['url', 'alt', 'title', 'translated'],
                    product: ['id'],
                },
                limit: 100,
            },
            )
        })

        const { cmsPage } = await res.json()

        setCmsPage(cmsPage)
        setLoading(false)
    }

    return (
        <>
            <a href={'#'} onClick={handleShow} className={`d-inline-block p-0 m-0${linkClassName ? ' ' + linkClassName : ''}`}>
                {buttonText}
            </a>

            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                size="xl"
            >
                {title &&
                    <Modal.Header closeButton>
                        <Modal.Title>{title}</Modal.Title>
                    </Modal.Header>
                }
                <Modal.Body>
                    {false === loading ? (
                        <>
                            {page.sections?.map(({ blocks }: any) => (
                                blocks.filter((block: any) => 'category-navigation' !== block.type).map((block: any, index: number) => (
                                    <Block key={'b_content' + index} id={cmsPageId} title={title} breadCrumb={[]} className={'py-2 fs-8'} {...block} />
                                ))
                            ))}
                        </>
                    ) : (
                        <Container>
                            <div className={'h-100 my-5 d-flex justify-content-center align-items-center'}>
                                <div className={'h-100 d-flex flex-column justify-content-center align-items-center'}>
                                    <Spinner animation="border" variant="secondary" />
                                    <span className={'mt-2'}>{t('loading')}</span>
                                </div>
                            </div>
                        </Container>
                    )}
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ModalCMS
