'use client'

//import { fbEvent } from '@rivercode/facebook-conversion-api-nextjs';

const facebookSendEvent = (event: string, ntmData: any, callback?: () => void) => {
    const { CookieFirst }: any = window

    const fbEvent = (params: any) => null

    if (CookieFirst) {
        if (true === CookieFirst.consent?.advertising) {
            fbEvent({
                eventName: event,
                enableStandardPixel: false,
                ...(process.env.NEXT_PUBLIC_FB_TEST_EVENT_CODE && { testEventCode: process.env.NEXT_PUBLIC_FB_TEST_EVENT_CODE }),
                ...ntmData,
            });
        }

        if (callback) callback();
    }
}

export default facebookSendEvent
