'use client'

import { type AddToCartButtonProps } from '.';
import Button from '@components/atoms/button';
import cn from 'classnames';
import { handleAddToCart, handleUpdateItemFromCart } from '@app/actions';
import FormControl from '@components/atoms/form-control';
import { toast } from 'react-toastify';
import Spinner from 'react-bootstrap/Spinner';
import { useCartContext } from '@context/cart-provider';
import { useState } from 'react';

const AddToCart = ({
  productId,
  parentId,
  disabled,
  quantity = 1,
  purchaseUnit = 1,
  referenceUnit = 1,
  maxPurchase,
  variant = 'extended',
  label = '',
  productNumber,
  deliveryTime,
  seoUrls,
  productName,
  callBack = () => { },
  ...props
}: AddToCartButtonProps) => {
  const [loading, setLoading] = useState(false);

  const { cart, setCart, setLoading: cartSetLoading, setShowOffcanvas } = useCartContext()

  const t = (str: string) => <>{str}</>

  const onSubmit = async (event: any) => {
    setLoading(true)
    cartSetLoading(true)

    event.preventDefault()
    var formData = new FormData(event.nativeEvent.target)


    const updateCart = (cart: any) => {
      if (cart.errors.length) {
        cart.errors.forEach(({ detail }: { detail: string }) => {
          toast.error(t(detail))
        })
      }

      setCart(cart)

      setLoading(false)
      cartSetLoading(false)
      setShowOffcanvas(true)

      callBack()
    }

    const lineItem = cart?.lineItems?.find((lineItem: any) => String(formData.get('id')) === lineItem.referencedId)
    if (undefined === lineItem) {
      await handleAddToCart(formData).then(updateCart)
    } else {
      formData.set('qty', lineItem?.quantity + quantity)
      await handleUpdateItemFromCart(formData).then(updateCart)
    }
  }

  return (
    <form onSubmit={onSubmit}>
      <FormControl id={'id'} name={'id'} type={'hidden'} defaultValue={productId} controlled={false} />
      <FormControl id={'qty'} name={'qty'} type={'hidden'} defaultValue={quantity} />
      {parentId && <FormControl id={'parentId'} name={'parentId'} type={'hidden'} defaultValue={parentId} controlled={false} />}

      <Button
        variant={'secondary'}
        disabled={loading || disabled}
        className={cn('w-100', {
          'p-2  border-0': 'small' === variant,
          'icon  icon--cart  icon--white': false === loading,
          [`${props.className}`]: props.className
        })}
        aria-label={`${productName ? `${quantity ? `${quantity * purchaseUnit}x ` : ''}'${productName}'` : `${quantity}x Artikel`} in den Warenkorb legen.`}
        type={'submit'}
        style={{ width: 38, height: 38 }}
      >
        {loading && (
          <Spinner size={'sm'}>
            <span className="visually-hidden">Lädt ...</span></Spinner>
        )}
        {'extended' === variant && ('' !== label ? label : t('add_to_cart'))}
      </Button>
    </form >
  );
};

export default AddToCart;
