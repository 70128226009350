import { useState, useEffect } from 'react';

function getOrigin() {
    const { location: { origin } } = window;
    return origin
}

export default function useOrigin() {
    const [origin, setOrigin] = useState<string | null>(null);

    useEffect(() => {
        if (null !== origin) return
        setOrigin(getOrigin());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return origin
}
